import React from 'react'
import "./branches.css"

import afterschool from "../../images/contactpageimg/Afterschool.png"

import { Link } from "react-router-dom";

const Branches = () => {
    return (
        <>


            <section className="branches">

                <div className="container  ">


                    <h2 className="text-center tt">Our Branche</h2>
                    <div className="row">
                        <div className="col-lg-5 col-md-12">
                            <Link to="/nizamudin">
                                <div className="card " style={{ width: "30rem;" }}>
                                    <img className="card-img-top" src="./images/branchimg/nizam.jpg" alt="Card image cap" />
                                    <div className="progress" style={{ height: "9px" }}>
                                        <div className="progress-bar bg-warning" role="progressbar" style={{ width: "100%" }} aria-valuenow="100"
                                            aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div className="card-body first">


                                        <h5 className="card-title text-center">Hermann Gmeiner Vocational Training Institute, Nizamuddin</h5>
                                        {/* <p className="text-center"><b>Nizamuddin</b></p> */}


                                        <p className="card-text  ">Dedicated to empowering youth from disadvantaged sections of society by offering quality skill training in IT, Management & Professional Skills. We prepare youth to be job-ready through our holistic training programmes.

                                        </p>

                                    </div>

                                    <div className="details first">

                                        <div className="contactbranch">
                                            <h6 className="pt-1"> Contact Us-:</h6>
                                            <p className="num">+91 7988524913, 011-43357299</p>
                                        </div>

                                        <div className="email">
                                            <h6 className="pt-1">Email-:</h6>
                                            <p className="num">bharat.rawat@soscvindia.org
                                            </p>
                                        </div>


                                        <a href="/" className='cardbtnf'>Know More</a>



                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <Link to="/raipur">
                                <div className="card " style={{ width: "30rem;" }}>
                                    <img className="card-img-top" src="./images/branchimg/raipur.jpg" alt="Card image cap" />
                                    <div className="progress" style={{ height: "9px" }}>
                                        <div className="progress-bar bg-warning" role="progressbar" style={{ width: "100%" }} aria-valuenow="100"
                                            aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div className="card-body second">


                                        <h5 className="card-title text-center">Hermann Gmeiner Vocational Training Institute, Raipur</h5>
                                        {/* <p className="text-center"><b>Nizamuddin</b></p> */}


                                        <p className="card-text  ">The first ever Vocational Training Centre opened by SOS Children’s Villages of India. The centre offers skill training in courses falling under Automotive and IT Sectors with access to the best of infrastructure and facilities. 

                                        </p>

                                    </div>

                                    <div className="details second">

                                        <div className="contactbranch">
                                            <h6 className="pt-1"> Contact Us-:</h6>
                                            <p className="num">+91-9589447399, 0771-7132196 </p>
                                        </div>

                                        <div className="email">
                                            <h6 className="pt-1">Email-:</h6>
                                            <p className="num">principal.raipurvtc@soscvindia.org
</p>
                                        </div>


                                        <a href="/" className='cardbtns'>Know More</a>



                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-lg-5 col-md-12">
                            <Link to="/bhakatpara">
                                <div className="card " style={{ width: " 30rem;" }}>
                                    <img className="card-img-top"src="./images/branchimg/bhakat.jpg"  alt="Card image cap" />
                                    <div className="progress" style={{ height: "9px" }}>
                                        <div className="progress-bar bg-warning" role="progressbar" style={{ width: "100%" }} aria-valuenow="100"
                                            aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div className="card-body third">


                                        <h5 className="card-title text-center">Hermann Gmeiner Vocational Training Institute, Bhakatpara</h5>
                                        {/* <p className="text-center"><b>Bhakatpara
                                        </b></p> */}


                                        <p className="card-text  ">Enabling marginalized women of Bhakatpara for sustainable livelihood opportunities through accredited skill training in the handloom sector. Our aim is to offer structured skill training with access to technologically upgraded equipment.</p>

                                    </div>

                                    <div className="details third ">

                                        <div className="contactbranch">
                                            <h6 className="pt-1">Contact Us-:</h6>
                                            <p className="num">+91-9706159127
</p>
                                        </div>

                                        <div className="email">
                                            <h6 className="pt-1">Email-:</h6>
                                            <p className="num">Dipankar.Hazarika@soscvindia.org</p>
                                        </div>

                                        <a href="/" className='cardbtnt'>Know More</a>



                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <Link to="/school">
                                <div className="card " style={{ width: " 30rem;" }}>
                                    <img className="card-img-top" src={afterschool} alt="Card image cap" />
                                    <div className="progress" style={{ height: "9px" }}>
                                        <div className="progress-bar bg-warning" role="progressbar" style={{ width: "100%" }} aria-valuenow="100"
                                            aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div className="card-body fourth">


                                        <h5 className="card-title text-center">Hermann Gmeiner After School Vocational Training Institutes</h5>
                                        {/* <p className="text-center"><b>Bhakatpara
                                        </b></p> */}


                                        <p className="card-text  ">Hubs of diversified skilling programmes in the fields of Retail and Sales, Information Technology, Cutting & Tailoring and many more. We offer diverse training programmes keeping in mind the needs & requirements of the community we are catering to.</p>

                                    </div>

                                    <div className="details fourth ">

                                        <div className="contactbranch">
                                            <h6 className="pt-1"> Contact Us-:</h6>
                                            <p className="num">8765814857, 9899786561</p>
                                        </div>

                                        <div className="email">
                                            <h6 className="pt-1">Email-:</h6>
                                            <p className="num">admin.hgpsanangpur@soscvindia.org</p>
                                        </div>

                                        <a href="/" className='cardbtnt'>Know More</a>



                                    </div>
                                </div>
                            </Link>
                        </div>

                    </div>
                </div>
            </section>


        </>
    )
}

export default Branches
